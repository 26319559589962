import  { useState, useEffect } from 'react';

import axios from "axios";
import {useHistory} from "react-router-dom";

const useUserGeoLocation = ({redirectUrl, redirect, restrictedLocations}) => {

  const [countryCode, setCoutryCode] = useState('');
  const [loading, setloading] = useState(true);
  const history = useHistory();

   // creating function to load ip address from the API
  const getIpData = async() => {
    try {
      const res = await axios.get('https://api.country.is/')
      setCoutryCode(res.data.country)
      setloading(false);
    } catch (error) {
      console.error('Error getting user country', error)
      setCoutryCode('US')
      setloading(false);
    }
  }

  useEffect(()=>{
    getIpData()
  },[]);

  if(redirect && restrictedLocations.includes(countryCode)) {
    history.push(redirectUrl);
  }

  return {
    countryCode,
    loading
  }
}

export default useUserGeoLocation;