import React, {useEffect} from "react";
import "./static/assets/fonts/proxima-nova.css";
import "./App.css";
import "./containers/StepsContainer/Steps.css";
import {Route, BrowserRouter as Router, Switch, Redirect} from "react-router-dom";
import {
    AMAZON_CHECKOUT,
    AMAZON_REGISTRATION_SUMMARY,
    FREE_HOMESCHOOL,
    FREE_TEACHER,
    LANDING,
    LIFETIME,
    READ_ALONG,
    REDEEM,
    START,
    GIFT,
    GIFT_REDEEM,
    REDEEM_MATH_MASTERS
} from "./constants/routes";
import NotFound from "./components/NotFound/NotFound";
import Default from "./pages";
import Redeem from "./pages/redeem";
import RedeemMathMasters from "./pages/redeem-math-masters";
import Start from "./pages/start";
import AmazonRegistrationSummary from "./pages/amazon-pay/registration-summary";
import AmazonCheckout from "./pages/amazon-pay";
import FreeTeacher from "./pages/free-teacher";
// import ReadAlong from "./pages/read-along";
import FreeHomeschool from "./pages/free-homeschool";
import Lifetime from "./pages/lifetime";
import Gift from "./pages/gift";
import GiftRedeem from "./pages/giftRedeem";
import {FREE_TEACHER_DISABLED, FREE_HOMESCHOOL_DISABLED, LIFETIME_DISABLED} from "./constants/constants";

const App = () => {

    useEffect(() => {
        localStorage.removeItem("userToken");
    }, []);

    return (
        <Router>
            <Switch>
                {/* <Route exact path={AMAZON_REGISTRATION_SUMMARY}>
                    <AmazonRegistrationSummary />
                </Route>
                <Route exact path={AMAZON_CHECKOUT}>
                    <AmazonCheckout />
                </Route> */}
                <Route exact path={FREE_TEACHER}>
                    {FREE_TEACHER_DISABLED ? <Redirect to="/"/> : <FreeTeacher />}
                </Route>
                <Route exact path={FREE_HOMESCHOOL}>
                    {FREE_HOMESCHOOL_DISABLED ? <Redirect to="/"/> : <FreeHomeschool />}
                </Route>
                {/* TODO: REMOVE all read-along related files */}
                {/* <Route exact path={READ_ALONG}>
                    <ReadAlong />
                </Route> */}
                <Route exact path={START}>
                    <Start />
                </Route>
                <Route exact path={REDEEM}>
                    <Redeem />
                </Route>
                <Route exact path={REDEEM_MATH_MASTERS}>
                    <RedeemMathMasters />
                </Route>
                {!LIFETIME_DISABLED && (
                    <Route exact path={LIFETIME}>
                        <Lifetime/>
                    </Route>
                )}
                <Route exact path={GIFT}>
                    <Gift />
                </Route>
                <Route exact path={GIFT_REDEEM}>
                    <GiftRedeem />
                </Route>
                <Route exact path={LANDING}>
                    <Default />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
