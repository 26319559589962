import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.js";
import "./Step2.css";
import {klaviyoEvent} from "../../utils/events";
import {useDispatch, useSelector} from "react-redux";
import {
    clearAllCouponInfo,
    createSubscription,
    getCouponInfoState,
    getCreateSubscriptionState,
    goToSection
} from "../../stores/registration";
import {REGISTRATION_SUMMARY} from "../../constants/routes";
import {EVENTS, SOURCES} from "../../constants/events";
import {getStripePromise} from "../../stores/stripe";
import Loading from "../Loading/Loading";
import {getPricesState, getPrices} from "../../stores/prices";
import {roundCentsAmount} from "../../utils/currencyUtils";
import DefaultFlowPricesList from "./DefaultFlowPricesList";
import Spinner from "../Spinner/Spinner";
import {selectPrice} from "../../utils/planUtils";
import {Box, Text} from "@chakra-ui/react";
import {validateStep2Form} from "../../utils/validationUtils";
import useCustomToast from "../../lib/hooks/toast";
import useQuery from "../../lib/hooks/useQuery";
import {QUERY_PARAMS_IDS} from "../../constants/standards";
import {USER_CREATION_ENDPOINT} from "../../constants/backend-sources";
import useUTMs from "../../lib/hooks/useUTMs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Step2 = ({state, setState, setSelectedPrice, selectedPrice, setSubscription, code, setCode}) => {
    const query = useQuery();
    const UTMs = useUTMs();
    const promo = query.get(QUERY_PARAMS_IDS.PROMO);

    const {
        customerName,
        email,
        password,
        country,
        newsletter,
    } = state;

    const stripePromise = useSelector(getStripePromise);
    const dispatch = useDispatch();
    const toast = useCustomToast();
    const {prices, loading: loadingPrices, error: pricesError} = useSelector(getPricesState);
    const {fulfilled, error, loading} = useSelector(getCreateSubscriptionState);
    const {coupon} = useSelector(getCouponInfoState);

    const [showSpinner, setShowSpinner] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    
    const handleChange = (event) => {
        const target = event.target;
        const value = target.name === "newsletter" ? target.checked : target.value;
        const name = target.name;
        if (name === "subscription") {
            setSelectedPrice(selectPrice(prices, value));
            return;
        }
        ;
        setState({[name]: value});
    }

    const handleClick = async (error, paymentMethod) => {
        const validationMessage = validateStep2Form({error, customerName});
        if (validationMessage) {
            toast({type: 'error', message: validationMessage, duration: 5000});
            setShowSpinner(false);
            return;
        }

        const payload = {
            email,
            password,
            password_confirmation: password,
            join_newsletter: newsletter,
            country_code: country,
            coupon: promo || code,
            promoCode: promo && code,
            price: selectedPrice.price,
            source: SOURCES.DEFAULT_FLOW.WEB,
            paymentMethod: paymentMethod.id,
            UTMs,
        }        

        payload.token = await executeRecaptcha('submit');

        try{
            window.inc("start_checkout"); 
            const key = await window.inc("go");
            console.log('Incendium key', key)
            payload.UTMs = {...UTMs, incendium_key: key};
        }catch(e){
            console.log('Error getting incendium key', e)
        }

        dispatch(createSubscription({
            payload, userCreationEndpoint: USER_CREATION_ENDPOINT.DEFAULT
        }));
    }

    useEffect(() => {
        window.fbq("trackCustom", "Start Checkout - Page 2", {
            content_category: "checkout_02",
        });
        klaviyoEvent(email, password, EVENTS.AFTER_VALIDATION, {
            email,
            source: SOURCES.DEFAULT_FLOW.AFTER_VALIDATION,
            ...UTMs,
        });
    }, []);

    useEffect(() => {
        if (fulfilled) {
            setSubscription(fulfilled.subscription);
            if (coupon)
                klaviyoEvent(email, password, EVENTS.COUPON_APPLIED_TRIAL_7, {
                    email,
                    coupon: code,
                    source: SOURCES.DEFAULT_FLOW.STEP_TWO,
                    ...UTMs
                });

            window.fbq("trackCustom", selectedPrice.interval === 'month'
                ? "Purchase - Monthly"
                : "Purchase - Annual", {
                currency: selectedPrice.currency.toUpperCase(),
                value: roundCentsAmount(selectedPrice.amount),
            });

            window.fbq("trackCustom", "Purchase - Any");

            dispatch(goToSection(REGISTRATION_SUMMARY));
        }
    }, [fulfilled])

    useEffect(() => {
        if (error) toast({type: 'error', message: error.message, duration: 5000});
    }, [error]);

    useEffect(() => {
        setShowSpinner(loading);
    }, [loading]);

    useEffect(() => {
        dispatch(clearAllCouponInfo());
        dispatch(getPrices(country));
    }, [country])

    useEffect(() => {
        if (selectedPrice) setSelectedPrice(selectPrice(prices, selectedPrice.metadata.slug));
    }, [prices])

    if (pricesError) return null;

    return (
        <div>
            <div className="hero">
                <Text fontFamily="proxima-soft" fontSize="32px" fontWeight="800" margin="8px" lineHeight="110%">
                    Choose Your Plan
                </Text>
                <span>Start Your 7 Day Free Trial!</span>
            </div>
            <div className="step">
                {showSpinner && <Loading/>}
                <h2>Step 3 of 3</h2>
                <div className="form">
                    <Box mt="16px">
                        {loadingPrices && (
                            <div className="centered-spinner">
                                <Spinner className="spinner-non-fixed"/>
                            </div>
                        )}
                        {!loadingPrices && prices && (
                            <DefaultFlowPricesList
                                handleChange={handleChange}
                                selectedPrice={selectedPrice}
                                mt="30px"
                            />
                        )}
                        <Elements
                            stripe={stripePromise}
                            options={{
                                fonts: [{cssSrc: "https://use.typekit.net/kbk5xha.css"}],
                            }}
                        >
                            {selectedPrice && !loadingPrices && (
                                <CheckoutForm
                                    state={state}
                                    selectedPrice={selectedPrice}
                                    handleChange={handleChange}
                                    clickHandler={handleClick}
                                    setShowSpinner={setShowSpinner}
                                    customerName={customerName}
                                    code={code}
                                    setCode={setCode}
                                />
                            )}
                        </Elements>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default Step2;
